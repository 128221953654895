@tailwind base;
@tailwind components;
@tailwind utilities;

.tw-hidden {
  display: none;
}

@font-face {
  font-family: 'rsuite-icon-font';
  src: local('rsuite-icon-font'),
    url(./../../public/font/rsuite-icon-font.ttf) format('truetype');
}

@font-face {
  font-family: 'rsuite-icon-font';
  src: local('rsuite-icon-font'),
    url(./../../public/font/rsuite-icon-font.woff) format('woff');
}

.elevation-1 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);
}

.elevation-2 {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.12);
}

.elevation-3 {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1),
    0px 1px 4px -1px rgba(0, 0, 0, 0.1);
}

.elevation-4 {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.rs-input-group-addon{
  padding:0px !important;
}
.rs-input-number-btn-group-vertical{
display: none !important;
}
.rs-input-number {
  border-radius: 0px !important;
}